import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Input from '../../../components/Forms/Input';
import { Button1 } from '../../../components/Forms/FormItems';
import useForm from '../../../Hooks/useForm';
import Error from '../../../helper/Error';

import { AuthContext } from '../../../contexts/AuthContext';
import google from '../../../icons/google.png';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { Alert } from '@material-ui/core';

const LoginPage = () => {
  const email = useForm({});
  const password = useForm({});

  const history = useHistory();

  const {
    signIn,
    loadingAuth,
    click,
    setLoadingAuth,
    getMessageByErrorCode,
    setClick,
    loginGoogle,
    setLogged,
  } = React.useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState(null);
  const [error, setError] = useState(null);

  function handleSubmit(event) {
    event.preventDefault();
    signIn(email.value, password.value).then((value) => {
      setClick(true);

      value && setError(` - ${value.code}`);
      value && setErrorMessage(getMessageByErrorCode(value.code));
    });
  }

  function handleLogged(event) {
    if (event) {
      setLogged(true);
    } else {
      setLogged(false);
    }
  }

  function onChangeScreen() {
    history.push('/Cadastrar');
    setClick(true);
  }

  document.body.style.backgroundColor = 'white';

  React.useEffect(() => {
    setClick(null);
    setLoadingAuth(false);
    window.scrollTo(0, 0);

    return () => {
      setClick(null);
      setLoadingAuth(false);
    };
  }, []);

  return (
    <section
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vh',
        width: '50vw',
        marginLeft: '-15%',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '60vw',
          height: 'auto',
          marginTop: '150px',
        }}
      >
        {/* Coluna de Login */}
        <div style={{ flex: 1, paddingRight: '50px' }}>
          <h1 style={{ fontSize: '32px', marginBottom: '20px' }}>Login</h1>
          <form onSubmit={handleSubmit}>
            <Input
              label="E-mail"
              name="email"
              {...email}
              style={{ padding: '10px', fontSize: '16px', width: '100%' }}
            />
            <Input
              label="Senha"
              type="password"
              name="password"
              {...password}
              style={{ padding: '10px', fontSize: '16px', width: '100%' }}
            />

            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(event) => {
                      handleLogged(event.target.checked);
                    }}
                    sx={{ padding: '5px' }}
                  />
                }
                label="Mantenha-me Conectado"
                style={{ fontSize: '14px' }}
              />
            </FormGroup>

            {loadingAuth ? (
              <Button1 disabled style={{ padding: '12px', fontSize: '16px' }}>
                Carregando...
              </Button1>
            ) : (
              <Button1 style={{ padding: '12px', fontSize: '16px' }}>
                Entrar
              </Button1>
            )}

            {click && error === undefined && email.value !== '' && (
              <Alert>
                <h6>Login Efetuado com Sucesso</h6>
              </Alert>
            )}

            <div>
              <Error error={errorMessage} />
            </div>

            <Link
              to="/RecuperarSenha"
              style={{ fontSize: '14px', marginTop: '10px' }}
            >
              Esqueceu a Senha?
            </Link>
          </form>
        </div>

        {/* Coluna de Cadastro */}
        <div
          style={{
            flex: 1,
            paddingLeft: '50px',
            borderLeft: '1px solid #ddd',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <button
            onClick={loginGoogle}
            style={{
              marginTop: '80px',
              padding: '12px',
              fontSize: '16px',
              width: '100%',
              maxWidth: '200px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#4285F4',
              color: '#fff',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              // marginBottom: '30px',
            }}
          >
            <img
              src={google}
              alt="Google logo"
              style={{
                marginRight: '10px',
                width: '24px',
              }}
            />
            Login com Google
          </button>
          <p style={{ marginTop: '40px', marginBottom: '40px' }}>OU</p>
          <h2 style={{ fontSize: '28px', marginBottom: '15px' }}>
            Cadastre-se
          </h2>
          <p style={{ fontSize: '14px', textAlign: 'center' }}>
            Ainda não possui conta? Cadastre-se no site.
          </p>

          <button
            onClick={onChangeScreen}
            style={{
              marginTop: '10px',
              padding: '12px',
              fontSize: '16px',
              width: '100%',
              maxWidth: '200px',
              backgroundColor: '#007BFF',
              color: '#fff',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
          >
            Cadastrar
          </button>
        </div>
      </div>
    </section>
  );
};

export default LoginPage;
